<template>
  <div>
    <waste-exchange-bed-confirm></waste-exchange-bed-confirm>
  </div>
</template>

<script>
import WasteExchangeBedConfirm from "@/components/ecologist/wasteExchange/wasteExchangeBedConfirm";

export default {
  name: "wasteExchangeBedConfirmView",
  components: { WasteExchangeBedConfirm },
};
</script>

<style scoped></style>
