<template>
    <div>
        <div id="print-content" class="el-row" >
            <el-row :gutter="3" v-if="type==1" class="el-row-acceptedLot">
                {{"Вы приняли предложение: " + BidInfo.organization.name }}
                {{"  на лот " + Bid.lots_id}}
            </el-row>
            <el-row :gutter="3" v-if="type==2" class="el-row-acceptedLot">
                {{"Вы ответили на предложение: " + lot.adress }}
                {{"  на лот " + Bid.lots_id}}
            </el-row>
            <el-row :gutter="3">
               <span class="el-row-acceptedLot-name">{{ lot.waste_code + " - " + lot.waste_name }}</span>
            </el-row>
            <el-row :gutter="3" >
                <div>
                    <span class="el-row-acceptedLot-inner">Масса:</span> {{  + Bid.mass}} {{
                            lot.mass_max != 0
                                ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                                    .name
                                : "Nan"
                    }}
                </div>

            </el-row>
            <el-row :gutter="3">
                <span class="el-row-acceptedLot-inner">Цена:</span>{{ Bid.price + " BYN/"  +
                            (lot.mass_max != 0
                                ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                                    .name
                                : "Nan")}}
            </el-row>
            <el-row :gutter="3">
                <span class="el-row-acceptedLot-inner">Сумма:</span>
                {{  Math.round(Bid.price*Bid.mass*1000)/1000+ " BYN"}}
            </el-row>
            <el-row :gutter="3" v-if="type==1">
                <span class="el-row-acceptedLot-inner">Местоположение:</span>
                {{  Bid.adress}}
            </el-row>
            <el-row :gutter="3" v-if="type==2">
                {{"Местоположение: " + BidInfo.password}}
            </el-row>
            <el-row :gutter="3">
                <div v-if="lot.disposal == 1"><span class="el-row-acceptedLot-inner">Перевозка:</span> транспортом производителя</div>
                <div v-if="lot.disposal == 2"><span class="el-row-acceptedLot-inner">Перевозка:</span> транспортом переработчика</div>
                <div v-if="lot.disposal == 3"><span class="el-row-acceptedLot-inner">Перевозка:</span> транспортом иной организации</div>
            </el-row>
            <el-row :gutter="3">
                <span class="el-row-acceptedLot-inner">Контакты:</span>
                {{ BidInfo.fullName+" " + BidInfo.phoneNumber}}
            </el-row>
            <el-row :gutter="3">
                <span class="el-row-acceptedLot-inner">Примечание:</span>
                {{ Bid.text}}
            </el-row>
            <el-row :gutter="3">
                <span class="el-row-acceptedLot-inner">Состав:</span>
                {{ Bid.composition}}
            </el-row>
        </div>
        <el-row :gutter="3">
            <el-col>

            </el-col>
            <el-col>
                <el-button type="primary" @click="CallPrint('print-content')">Печать</el-button>
            </el-col>
            <el-col>

            </el-col>
        </el-row>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
// import { mapGetters } from "vuex";

export default {
  props: ["Bid","lot","type"],
  name: "userDate",
  data() {
    return {

        BidInfo:{
            organization:{name:null},

        },

        pss93UnitsInfoList: [{ id: null, name: null }],
    };
  },
  methods: {
    CallPrint(strid) {
        var prtContent = document.getElementById(strid);
        // var prtCSS = '<link rel="stylesheet" href="/templates/css/template.css" type="text/css" />';
        var prtCSS = '<link rel="stylesheet" href="/template/css/print.css" type="text/css" />';
        var WinPrint = window.open('','','left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0');
        WinPrint.document.write('<div id="print" class="contentpane">');
        WinPrint.document.write(prtCSS);
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.write('</div>');
        WinPrint.document.close();
        WinPrint.focus();
        //WinPrint.print();
        //WinPrint.close();
        //prtContent.innerHTML=strOldOne;
    },
    async getSubClassifierById(id) {
        let data = {
          id: id,
          size: 100,
          page: 0,
        };
        return await this.$store.dispatch(
          "getAllDataSubClassifierForSelect",
          data
        );
      },
  },
  computed: {
    ...mapGetters({
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
      foundedWaste: "GETSS01DICTIONARY",
    }),
  },
  mounted() {
    console.log(this);
    this.$store.dispatch("getInfo",this.type == 1 ? this.Bid.user_id : this.lot.user_id).then((response) => {
        this.BidInfo = response.data;
      });
      this.getSubClassifierById(93).then((response) => {
        this.pss93UnitsInfoList = response.data.content;
      });
  },
};
</script>

<style scoped>
.el-row {
    padding-bottom: 10px;
}
.el-row-acceptedLot{
    color: #1B1B1B;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 10px;
}
.el-row-acceptedLot-row{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
.el-row-acceptedLot-inner{
    color: #1B1B1B;
    font-size: 18px;
    font-weight: 700;
}
.el-row-acceptedLot-name{
    font-size: 15px;
    font-weight: 700;

}
</style>